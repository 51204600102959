import React from "react"
import { Form, Button, Col } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"

const ContactForm = () => {
  const intl = useIntl()
  return (
    <div className="contact-section">
      <div className="container">
        <div className="pb-3 page-title-container">
          <h2 className="page-title">
            {intl.formatMessage({ id: "contact.title" })}
            <div className="gray-underline"></div>
            <div className="orange-underline"></div>
          </h2>
        </div>
        <div className="contact-content">
          <div className="form">
            <Form
              action="https://submit-form.com/luUnTZ2WYZhqth4eQs-7d"
              target="_blank"
            >
              <Form.Row>
                <Form.Group as={Col} controlId="formGridFirstName">
                  <Form.Label>
                    {intl.formatMessage({ id: "contact.firstName" })}
                  </Form.Label>
                  <Form.Control name="firstName" type="text" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridLastName">
                  <Form.Label>
                    {intl.formatMessage({ id: "contact.lastName" })}
                  </Form.Label>
                  <Form.Control name="lastName" type="text" />
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="formGridEmail">
                <Form.Label>
                  {intl.formatMessage({ id: "contact.email" })}
                </Form.Label>
                <Form.Control name="email" type="text" />
              </Form.Group>
              <Form.Group controlId="formGridCompany">
                <Form.Label>
                  {intl.formatMessage({ id: "contact.company" })}
                </Form.Label>
                <Form.Control name="company" type="text" />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridCountry">
                  <Form.Label>
                    {intl.formatMessage({ id: "contact.country" })}
                  </Form.Label>
                  <Form.Control name="country" type="text" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>
                    {intl.formatMessage({ id: "contact.city" })}
                  </Form.Label>
                  <Form.Control name="city" type="text" />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formGridAddress">
                <Form.Label>
                  {intl.formatMessage({ id: "contact.address" })}
                </Form.Label>
                <Form.Control name="address" type="text" />
              </Form.Group>

              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>
                  {intl.formatMessage({ id: "contact.message" })}
                </Form.Label>
                <Form.Control name="message" as="textarea" rows={5} />
              </Form.Group>
              <div className="text-center">
                <Button variant="primary" type="submit">
                  {intl.formatMessage({ id: "contact.submit" })}
                </Button>
              </div>
            </Form>
          </div>
          <div className="company-info">
            <iframe
              width="100%"
              height="300"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=No.%20186%2C%20Section%202%2C%20Meicun%20Road%2C%20South%20District%20Taichung%20City%2C%20402&t=&z=13&ie=UTF8&iwloc=&output=embed"
              scrolling="no"
              frameBorder="0"
            ></iframe>
            <div className="d-flex">
              <div className="mx-auto">
                <h5 className="mt-4">
                  {intl.formatMessage({ id: "companyName" })}
                </h5>
                <div className="mt-1">
                  {intl.formatMessage({ id: "contact.address" })}:{" "}
                  {intl.formatMessage({ id: "footer.address" })}
                </div>

                <div className="mt-1">
                  {intl.formatMessage({ id: "footer.emailName" })}:
                  info@metalall-tw.com
                </div>
                <div className="mt-1">
                  {intl.formatMessage({ id: "footer.telName" })}:
                  +886-4-22630855
                </div>
                <div className="mt-1">
                  {intl.formatMessage({ id: "footer.fax" })}: +886-4-22658814
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm

import React from "react"
import Layout from "../components/layout"
import { ContactForm } from "../components/contact"
import "../style/contact.scss"
import SEO from "../components/seo"

const Contact = props => {
  return (
    <Layout {...props}>
      <SEO />
      <div className="contact-page">
        <ContactForm />
        {/* <Banner /> */}
      </div>
    </Layout>
  )
}

export default Contact
